import {Controller} from "stimulus"
import $ from 'jquery';

require("jquery-ui-dist/jquery-ui.css")
require('jquery-ui-dist/jquery-ui')
require('moment/moment')

export default class extends Controller {
    connect() {
        let minDate = 0;
        let maxDate = 0;
        let number_of_days = 30;
        if (this.data.get('min') == null) {
            let expireDate = new Date();
            minDate = expireDate.setFullYear(expireDate.getFullYear() - 20);
        } else {
            minDate = this.data.get('min');
        }

        if (this.data.get('max') == null) {
            let expireDate = new Date();
            maxDate = expireDate.setFullYear(expireDate.getFullYear() + 20);
        } else {
            maxDate = this.data.get('max');
        }
        if (this.data.get('days') != null) {
            number_of_days = parseInt(this.data.get('days'));
        }

        let dateFormat = "dd/mm/yy",
            from = $(".date-range-from", this.element).datepicker({
                dateFormat: dateFormat,
                defaultDate: "1",
                changeMonth: true,
                numberOfMonths: 1,
                changeYear: true,
                showButtonPanel: true,
                closeText: 'Limpiar',
                yearRange: '1920:2121',
                minDate: new Date(minDate),
                maxDate: new Date(maxDate),
                onClose: onClose
            }),
            to = $(".date-range-to", this.element).datepicker({
                dateFormat: dateFormat,
                defaultDate: "1",
                changeMonth: true,
                numberOfMonths: 1,
                changeYear: true,
                showButtonPanel: true,
                closeText: 'Limpiar',
                yearRange: '1920:2121',
                onClose: onClose
            })
        from.on("change", function () {
            let date = (getDate(this) || new Date()).setDate((getDate(this) || new Date()).getDate() + number_of_days)
            let new_date = new Date(date)
            to.datepicker("option", "minDate", getDate(this));
            to.datepicker("option", "maxDate", new_date);
            if (to.val() === '')
                to.val(from.val());
        })
        to.on("change", function () {
            from.datepicker("option", "maxDate", (getDate(this) || new Date()));
            if (from.val() === '')
                from.val(to.val());
        });

        function getDate(element) {
            let date;
            try {
                date = $.datepicker.parseDate(dateFormat, element.value);
            } catch (error) {
                date = null;
            }
            return date;
        }

        function onClose(dateText, inst) {
            if ($(window.event.srcElement).hasClass('ui-datepicker-close')) {
                document.getElementById(this.id).value = '';
            }
        }

        $.datepicker.regional['es'] = {
            closeText: 'Cerrar',
            prevText: '<Ant',
            nextText: 'Sig>',
            currentText: 'Hoy',
            monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
            monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
            dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
            dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Juv', 'Vie', 'Sáb'],
            dayNamesMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sá'],
            weekHeader: 'Sm',
            dateFormat: 'dd/mm/yy',
            firstDay: 1,
            isRTL: false,
            showMonthAfterYear: false,
            yearSuffix: ''
        };
        $.datepicker.setDefaults($.datepicker.regional['es']);

        // disparar el evento tigger del from si este existe al cargar la página
        if (from.val() !== '' && from.val() !== undefined)
            $(document).ready(from.trigger('change'));
    }
}