import {Controller} from "stimulus";
import $ from "jquery";

export let ajaxErrorMessages = (jqXHR, textStatus, errorThrown) => {
    if (jqXHR.getResponseHeader('REQUIRES_AUTH') === '1') {
        alert('Su session expiró, por favor acceda nuevamente');
        window.location = jqXHR.getResponseHeader('ROOT_URL');
    }else{
        let message = "";
        if (jqXHR.status === 0) {
            message = 'Sin conexión: Verifique su conexión de red.';
        } else if(jqXHR.status == 400){
            message = ' Existen problemas en el request';
        } else if(jqXHR.status == 401){
            message = 'Autentiquece nuevamente.';
        }else if (jqXHR.status == 403) {
            message = "Autentiquece nuevamente.";
        } else if (jqXHR.status == 404) {
            message = 'Página no encontrada [404]';
        } else if (jqXHR.status == 500) {
            message = 'Ha ocurrido un error [500] que fue notificado al equipo de soporte, intente nuevamente en unos minutos.';
        } else if (jqXHR.status == 503){
            message = "Por problemas ajenos al SER y Come2ec, no se ha podido obtener la reserva. Contacte con el administrador de la Red";
        } else if (textStatus === 'parsererror') {
            message = 'Ha ocurrido un error de interno [JSON] que fue notificado al equipo de soporte, intente nuevamente en unos minutos';
        } else if (textStatus === 'timeout') {
            message = 'Time out, tiempo de espera superado, inicie nuevmente, verifique su conexión de red.';
        } else if (textStatus === 'abort') {
            message = 'Request abortado.';
        } else {
            message = 'Error desconocido: ' + jqXHR.responseText;
        }
        alert(message);
    }
}

// type of message : 'success', 'info' ,'warning', 'error'
// message : message to show
// title : message title
export let toastr_message = (type, message, title = "") => {
    toastr.options.closeButton = true;
    toastr.options.progressBar = true;
    toastr.options.preventDuplicates = false;
    toastr.options.showDuration = 400;
    toastr.options.hideDuration = 1000;
    toastr.options.timeOut = 10000;
    toastr.options.extendedTimeOut = 2000;

    toastr.options.showEasing = 'swing';
    toastr.options.hideEasing = 'linear';
    toastr.options.closeEasing = 'linear';
    toastr.options.showMethod = 'fadeIn';
    toastr.options.hideMethod = 'fadeOut';
    toastr.options.positionClass = 'toast-bottom-right';
    toastr[type](message, title)

}

/**
 * FUNCIÓN QUE REDONDEA UN FLOAT CON LA PRESICIÓN DESPUÉS DE LA COMA
 * @param num
 * @param precision
 * @returns {string}
 */
export let myToFixed = (num, precision) => {
    return (+(Math.round(+(num + 'e' + precision)) + 'e' + -precision)).toFixed(precision);
}

/** Aplica el descuento de la bolsa a un monto dado, retornando el monto resultante de aplicar el descuento y actualizando el mensaje de estado de la bolsa
 * @param amount :Number monto al cual descontar
 * @returns Number
 * */
export let applyCreditPoolDiscountToAmount = (amount, credit_pool, $credit_pool_msg_details) => {
    let result = 0.0
    if( amount >= credit_pool ){ // si el monto es mayor q la bolsa se devuelve la diferencia para q se continue trabajando cone él ... (bolsa completamente consuminda)
        result = myToFixed(amount - credit_pool, 2);
        $credit_pool_msg_details.html(`<ul class="mb-0 "><li> consumida totalmente en esta operación.</li></ul>`)
        $credit_pool_msg_details.prev().addClass('text-decoration-line-through')  // tachar monto anterior de la bolsa
    }else if(amount !== 0){ // monto es menor q la bolsa pero no es 0 ... (se muestra la bolsa restante)
        $credit_pool_msg_details.html(`<ul class="mb-0"><li><b>${myToFixed(credit_pool - amount, 2)}</b> disponibles tras esta operación</li></ul>`)
        $credit_pool_msg_details.prev().addClass('text-decoration-line-through')  // tachar monto anterior de la bolsa
    }else{ // el monto es 0 ... (se deja el alert como estaba, solo mostrando la bolsa disponible)
        $credit_pool_msg_details.empty()  // quitar detalles del msg
        $credit_pool_msg_details.prev().removeClass('text-decoration-line-through')  // destachar monto anterior de la bolsa
    }
    return result
}


export default class extends Controller {
    connect() {
    }
}