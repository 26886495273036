import {Controller} from "stimulus";
import $ from "jquery";
require('block-ui/jquery.blockUI');

export default class extends Controller {
    connect() {}

    enableBtnSaveContract(e){
        if($('.contract_items').is(':checked')){
            $('#btn_save_contract').removeClass('disabled');
        }else {
            $('#btn_save_contract').addClass('disabled');
        }
    }

    enableBtnDeleteContract(e){
        if($('.contract_assign_items').is(':checked')){
            $('#btn_delete_contract').removeClass('disabled');
        }else {
            $('#btn_delete_contract').addClass('disabled');
        }
    }


}