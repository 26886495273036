import {Controller} from "stimulus";
import $ from "jquery";
import {checkAdvancePaymentAtMoment} from "./payment_controller";
import {applyCreditPoolDiscountToAmount, myToFixed, toastr_message} from "./globalFunctions_controller";

function checkDriverAmount(e){
    let amounts_drivers_input = 0;
    //Poner de forma automática la cantidad a cobrar.
    $.each($('.identity_driver'), function( index, input ) {
        if($(this).val !== undefined && $(this).val().length > 0){
            amounts_drivers_input = amounts_drivers_input + 1
        }
    });
    // Cantidad de conductores gratis no asignados en el ciclo de renta
    let free_drivers_remaining = parseInt($('#free_drivers_remaining').val());
    let amount_to_charge = 0;

    // Si es mayor la cantidad de choferes gratis que no han sido asignados es mayor igual que la cantidad
    // especificada por el usuario, la cantidad de conductores a cobrar es cero (primero los gratis),
    // en caso contrario seria la diferencia entre la cantidad seleccionada menos la cantidad gratis.
    if(free_drivers_remaining >= amounts_drivers_input){
        amount_to_charge = 0
    }else{
        amount_to_charge = amounts_drivers_input - free_drivers_remaining
    }
    $('#additional_driver_collect').val(amount_to_charge).trigger('click');
}

export default class extends Controller {
    connect() {
        $('#cash').attr('disabled','disabled');
        $('#card').attr('disabled','disabled');
        $('#moment_card').attr('disabled','disabled');
        $('#cheque').attr('disabled','disabled');
        $('#other').attr('disabled','disabled');
        $('#voucher').attr('disabled','disabled');

        this.ajaxPaymentFormsByRentType(parseInt(40));

        // --- inicializar datos para uso de la Bolsa --- //
        // si por alguna casualidad no se encuentra el valor de la bolsa se le da valor 0.0
        this.credit_pool = $('#credit_pool').length > 0 ? Number($('#credit_pool').val()) : 0.0
        this.$credit_pool_msg_details = $('#credit_pool_msg_details')
    }

    toogleDrivers(e){
        let container = this.data.get("container");
        if($('#drivers_switch').is(":checked") === true) {
            $("#additional_drivers").show();
        }else{
            let additional_driver_collect = $('#additional_driver_collect');
            additional_driver_collect.val(0).trigger('click');
            $("#additional_drivers").hide();
        }
    }

    toogleRentedDrivers(e){
        if($('#rented_drivers_switch').is(":checked") === true) {
            $("#rented_additional_drivers_body").show();
        }else{
            $("#rented_additional_drivers_body").hide();
        }
    }

    toogleSingelDriver(e){
        let container = this.data.get("container");
        if(container !== undefined || container !== null ){
            if($('#hs_' + container).is(":checked") === true) {
                $("#additional_drivers_body_"+ container).show();
            }else{
                $("#additional_drivers_body_"+ container).hide();
            }
        }
    }

    // Additional Drivers
    additionalDrivers(e) {
        if($('#addDrivers').is(":checked") === true) {
            $("#additional_drivers").show();
        }else{
            $("#additional_drivers").hide();
            let additional_driver_collect = $('#additional_driver_collect');
            additional_driver_collect.val(0).trigger('click');
        }
        let length = parseInt(this.data.get('length'));
        let number_driver = parseInt(this.data.get('init'));
        let rent_type = this.data.get('rent_type');
        let years_experience = this.data.get('years-experience');
        let rent_to_date = this.data.get('rent_to_date');
        const rentTypeRef = $('#additional_drivers_body')
        rentTypeRef.empty();
        if (e.target.value) {
            $('.additional_drivers_section').fadeIn("slow")
            const countries = JSON.parse(this.data.get("countries"))
            for (let i = 1; i <= length; i++) {

                if(i === 1){
                    rentTypeRef.append(`
                    <div class="row form-group ${i < length ? 'mb-3' : ''} driver_${i}_container">
                        <div class="form-group mt-3 mb-3 row driver_${i}_form">
                            <div class="col">
                                <div style="position: relative;">
                                    <div class="fieldset-header">
                                        <h4 id="technical_sheet">
                                            Chofer adicional ${number_driver}
                                            <label class="form-check form-switch ml-1" style="float: right;" data-controller='addDrivers' data-addDrivers-container="${i}">
                                              <input class="form-check-input" type="checkbox" checked="" id="hs_${i}" data-action="click->addDrivers#toogleSingelDriver">
                                            </label>
                                        </h4>
                                    </div>
                                    <div class="fieldset-body-extend p-4" id="additional_drivers_body_${i}">
                                        <div class="row">
                                            <!-- Identidad -->
                                            <div class="col-md-3 col-sm-12 col-xs-12">
                                              <label class="form-label required">Identidad</label>
                                              <div class="input-group mb-2" data-controller='addDrivers' 
                                                                            data-addDrivers-additional_driver_container="${i}"
                                                                            data-addDrivers-rent_type="${rent_type}"
                                                                            data-addDrivers-rent_to_date="${rent_to_date}">
                                                <input class="form-control identity_driver" placeholder="Identidad" id="identity_card_${i}"  
                                                        name="driver[${i}][identity_card]" 
                                                        pattern="^[a-zA-Z0-9]{3,15}$" 
                                                        title="Carnet de indentidad o pasaporte no valido" 
                                                        data-action="blur->addDrivers#getAdditionalDriverData"/>
                                                <button class="btn btn-white" type="button" id="sip_${i}">S.I.P</button>
                                              </div>
                                            </div>
                                            <!-- Name -->
                                            <div class="col-md-3 col-sm-12 col-xs-12">
                                              <label class="form-label required">Nombre</label>
                                                <input class="form-control" id="name_driver_${i}" name="driver[${i}][name]" placeholder="Nombre" />
                                            </div>
                                            <!-- Apellido -->
                                            <div class="col-md-3 col-sm-12 col-xs-12">
                                                <label class="form-label required">Apellidos</label>
                                                <input class="form-control" placeholder="Apellidos" id="last_name_${i}" name="driver[${i}][last_name]" />
                                            </div>
                                           <!-- Fecha de nacimiento -->
                                            <div class="col-md-3 col-sm-12 col-xs-12">
                                                <label class="form-label required">Fecha de nacimiento
                                                <span class="form-check-description">
                                                    (Edad: <span id="driver_age_${i}"></span>)
                                                 </span>
                                                </label>
                                                <div data-controller='inputmask nomClient' 
                                                        data-nomClient-valid_age_url="/hire_point/validate_client_age"
                                                        data-nomClient-span_age_id="driver_age_${i}"
                                                        data-nomClient-rent_type_id="${rent_type}">
                                                    <input id="birth_date_${i}" name="driver[${i}][birth_date]"
                                                        class="form-control dmY_date" 
                                                        data-action="blur->nomClient#validAgeToRent"
                                                        placeholder="01/01/2000" 
                                                        data-mask="01/01/2000"
                                                        pattern='[0-3][0-9]/[0-1][0-9]/[1-2][0-9][0-9][0-9]'
                                                        title='Formato de Fecha, ej: 01/01/2000, 17/05/1984'
                                                        autocomplete="off" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <!-- Telefono -->
                                            <div class="col-md-3 col-sm-12 col-xs-12">
                                              <label class="form-label">Teléfono</label>
                                                <input class="form-control" placeholder="Telefono" id="phone_${i}" name="driver[${i}][phone]" />
                                            </div>
                                            <!-- Licencia -->
                                            <div class="col-md-3 col-sm-12 col-xs-12" data-controller="addDrivers">
                                              <label class="form-label required">Licencia</label>
                                                <input class="form-control" placeholder="Licencia" id="driver_license_${i}" name="driver[${i}][license]" />
                                                <label class="form-check form-check-inline" style="margin-right: 0px;">
                                                    <input class="form-check-input" type="checkbox" value="0" name="driver[${i}][permanent]" id="driver_permanent_${i}" 
                                                    data-container="${i}" data-action="change->addDrivers#licencePermanent">
                                                    <span class="form-check-label">¿Permanente?</span>
                                                </label>
                                            </div>
                                            <!-- Fecha de vencimiento -->
                                            <div class="col-md-3 col-sm-12 col-xs-12">
                                                <label class="form-label required">Fecha de vencimiento</label>
                                                <div data-controller='date addDrivers' data-date-min="${new Date()}">
                                                    <input id="due_date_${i}" 
                                                           data-rent_to_date="${rent_to_date}" 
                                                           data-container="${i}" 
                                                           name="driver[${i}][due_date]" 
                                                           class="form-control due_datepicker date" 
                                                           autocomplete="off" 
                                                           readonly="true"
                                                           data-action="change->addDrivers#checkDueDate"
                                                    />
                                                </div>
                                            </div>
                                            <div class="col-md-3 col-sm-12 col-xs-12">
                                              <label class="form-label required"'>
                                                Fecha de emisión
                                              </label>
                                              <div data-controller='datemax'>
                                                <input id="license_date${i}"
                                                    name="driver[${i}][license_date]" 
                                                    class="form-control license_date_datepicker date_max" 
                                                    autocomplete="off" 
                                                    readonly="true" />
                                              </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                         <!-- Pais -->
                                            <div class="col-md-3 col-sm-12 col-xs-12">
                                                <label class="form-label required">Nacionalidad</label>
                                                <select id="country_driver_${i}" name="driver[${i}][country_driver_id]" class="c2ec_select form-select nationalities"></select>
                                            </div>
                                            <div class="col-md-9 col-sm-12 col-xs-12">
                                                <label class="form-label">Dirección</label>
                                                <input class="form-control" placeholder="Dirección" id="address_${i}" name="driver[${i}][address]" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>      
                    </div>
                `);
                }else{
                    number_driver = number_driver + 1
                    rentTypeRef.append(`
                    <div class="row form-group ${i < length ? 'mb-3' : ''} driver_${i}_container">
                        <div class="form-group mt-3 mb-3 row driver_${i}_form">
                            <div class="col">
                                <div style="position: relative;">
                                    <div class="fieldset-header">
                                        <h4 id="technical_sheet">
                                            Chofer adicional ${number_driver}
                                            <label class="form-check form-switch ml-1" style="float: right;" data-controller='addDrivers' data-addDrivers-container="${i}">
                                              <input class="form-check-input" type="checkbox" id="hs_${i}" data-action="click->addDrivers#toogleSingelDriver">
                                            </label>
                                        </h4>
                                    </div>
                                    <div class="fieldset-body-extend p-4" id="additional_drivers_body_${i}" style="display: none;">
                                        <div class="row">
                                            <!-- Identidad -->
                                            <div class="col-md-3 col-sm-12 col-xs-12">
                                              <label class="form-label required">Identidad</label>
                                              <div class="input-group mb-2" data-controller="addDrivers" 
                                                                            data-addDrivers-additional_driver_container="${i}"
                                                                            data-addDrivers-rent_type="${rent_type}"
                                                                            data-addDrivers-rent_to_date="${rent_to_date}" >
                                                <input class="form-control identity_driver" placeholder="Identidad" id="identity_card_${i}"  
                                                        name="driver[${i}][identity_card]" pattern="^[a-zA-Z0-9]{3,15}$" 
                                                        title="Carnet de indentidad o pasaporte no válido" 
                                                        data-action="blur->addDrivers#getAdditionalDriverData"/>
                                                <button class="btn btn-white" type="button" id="sip_${i}">S.I.P</button>
                                              </div>
                                            </div>
                                            <!-- Name -->
                                            <div class="col-md-3 col-sm-12 col-xs-12">
                                              <label class="form-label required">Nombre</label>
                                                <input class="form-control" id="name_driver_${i}" name="driver[${i}][name]" placeholder="Nombre" />
                                            </div>
                                            <!-- Apellido -->
                                            <div class="col-md-3 col-sm-12 col-xs-12">
                                                <label class="form-label required">Apellidos</label>
                                                <input class="form-control" placeholder="Apellidos" id="last_name_${i}" name="driver[${i}][last_name]" />
                                            </div>
                                           <!-- Fecha de nacimiento -->
                                            <div class="col-md-3 col-sm-12 col-xs-12">
                                                <label class="form-label required">Fecha de nacimiento
                                                    <span class="form-check-description">
                                                        (Edad: <span id="driver_age_${i}"></span>)
                                                     </span>
                                                </label>
                                                <div data-controller='inputmask nomClient'
                                                     data-nomClient-valid_age_url="/hire_point/validate_client_age"
                                                     data-nomClient-span_age_id="driver_age_${i}"
                                                     data-nomClient-rent_type_id="${rent_type}">
                                                    <input id="birth_date_${i}" name="driver[${i}][birth_date]" 
                                                        class="form-control dmY_date" 
                                                        data-action="blur->nomClient#validAgeToRent"
                                                        placeholder="01/01/2000" 
                                                        data-mask="01/01/2000"
                                                        pattern='[0-3][0-9]/[0-1][0-9]/[1-2][0-9][0-9][0-9]'
                                                        title='Formato de Fecha, ej: 01/01/2000, 17/05/1984'
                                                        autocomplete="off" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <!-- Telefono -->
                                            <div class="col-md-3 col-sm-12 col-xs-12">
                                              <label class="form-label">Teléfono</label>
                                                <input class="form-control" placeholder="Telefono" id="phone_${i}" name="driver[${i}][phone]" />
                                            </div>
                                            <!-- Licencia -->
                                            <div class="col-md-3 col-sm-12 col-xs-12" data-controller='addDrivers'>
                                              <label class="form-label required">Licencia</label>
                                                <input class="form-control" placeholder="Licencia" id="driver_license_${i}" name="driver[${i}][license]" />
                                                <label class="form-check form-check-inline" style="margin-right: 0px;">
                                                    <input class="form-check-input" type="checkbox" value="0" name="driver[${i}][permanent]" id="driver_permanent_${i}"
                                                    data-action="change->addDrivers#licencePermanent" data-container="${i}">
                                                    <span class="form-check-label">¿Permanente?</span>
                                                </label>
                                            </div>
                                            <!-- Fecha de vencimiento -->
                                            <div class="col-md-3 col-sm-12 col-xs-12">
                                                <label class="form-label required">Fecha de vencimiento</label>
                                                <div data-controller='date addDrivers' data-date-min="${new Date()}">
                                                    <input id="due_date_${i}" 
                                                           data-rent_to_date="${rent_to_date}" 
                                                           data-container="${i}"
                                                           name="driver[${i}][due_date]" 
                                                           class="form-control date due_date_datepicker" 
                                                           autocomplete="off" 
                                                           readonly="true"
                                                           data-action="change->addDrivers#checkDueDate"
                                                    />
                                                </div>
                                            </div>
                                            <div class="col-md-3 col-sm-12 col-xs-12">
                                              <label class="form-label required"'>
                                                Fecha de emisión
                                              </label>
                                              <div data-controller='datemax'>
                                                <input id="license_date${i}"
                                                    name="driver[${i}][license_date]" 
                                                    class="form-control date_max license_date_datepicker" 
                                                    autocomplete="off" 
                                                    readonly="true" />
                                              </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                         <!-- Pais -->
                                            <div class="col-md-3 col-sm-12 col-xs-12">
                                                <label class="form-label required">Nacionalidad</label>
                                                <select id="country_driver_${i}" name="driver[${i}][country_driver_id]" class="c2ec_select form-select nationalities"></select>
                                            </div>
                                            <div class="col-md-9 col-sm-12 col-xs-12">
                                                <label class="form-label">Dirección</label>
                                                <input class="form-control" placeholder="Dirección" id="address_${i}" name="driver[${i}][address]" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>      
                    </div>
                `);
                }
                const countryRef = $(`#country_driver_${i}`)
                countryRef.append('<option selected="selected" value=""> -- Seleccione un país-- </option>')
                for (let country of Object.keys(countries)) {
                    let option = new Option(countries[country], country, false, false);
                    countryRef.append(option);
                }
                countryRef.select2({
                    theme: "bootstrap",
                    language: "es"
                });
            }
        } else {
            $('.additional_drivers_section').fadeOut("slow");
        }
        //Cambiando maximo permitido seleccionar para la fecha de emision de la licencia
        let yearsDate = new Date();
        yearsDate.setFullYear(yearsDate.getFullYear() - years_experience)
        // $('#license_date').datepicker('option', 'maxDate', new Date(yearsDate));

        $('.license_date_datepicker').datepicker({
            dateFormat: "dd/mm/yy",
            changeMonth: true,
            changeYear: true,
            numberOfMonths: 1,
            showButtonPanel: true,
            yearRange: '1920:2121',
            maxDate: new Date(yearsDate)
        });
    }

    getAdditionalDriverData(e) {
        // todo cambiar la url q busca los datos.
        e.preventDefault();
        let container = this.data.get('additional_driver_container');
        let rent_type = this.data.get('rent_type');
        let rent_to_date = this.data.get('rent_to_date');

        if (e.target.value !== ''){
            jQuery.ajax({
            async: true,
            evalScripts: true,
            type: "GET",
            contentType: "application/x-www-form-urlencoded",
            url: '/company/ser/configurations/list/additional-drivers/get_additional_driver_data',
            data: {identity_card: e.target.value, rent_type: rent_type, rent_to_date: rent_to_date},
            success: function (res) {
                if (res != undefined) {
                    let additional_driver = res.additional_driver;
                    if (res.error === true) {
                        $(`#identity_card_${container}`).val("").trigger('change');
                        toastr_message('error', res.error_msg);
                    } else if (additional_driver !== null) {
                        $(`#name_driver_${container}`).val(additional_driver.name);
                        $(`#identity_card_${container}`).val(additional_driver.identity);
                        $(`#last_name_${container}`).val(additional_driver.last_name);
                        $(`#birth_date_${container}`).val(res.driver_birth_date);
                        $(`#phone_${container}`).val(additional_driver.phone);
                        $(`#driver_license_${container}`).val(additional_driver.driving_license);
                        $(`#country_driver_${container}`).val(additional_driver.nom_country_id).trigger('change');
                        $(`#due_date_${container}`).val(res.driver_expiration_driving_license);
                        $(`#license_date${container}`).val(res.driver_license_date);

                        // Edad del chofer
                        if (res.age !== undefined) $(`#driver_age_${container}`).text(res.age)
                        if (res.invalid_age === true) $(`#birth_date_${container}`).val('');
                        if (res.error === false && res.warning_msg_age !== undefined && res.warning_msg_age !== '') toastr_message('warning', res.warning_msg_age);
                        // Fecha de vencimiento de la licencia
                        if (res.invalid_due_date === true) $(`#due_date_${container}`).val('');
                        if (res.error === false && res.warning_msg_due_date !== undefined && res.warning_msg_due_date !== '') toastr_message('warning', res.warning_msg_due_date);
                    }
                    if (res.error === false && res.warning_msg !== undefined && res.warning_msg !== '')
                        toastr_message('warning', res.warning_msg);
                }
                if($(`#identity_card_${container}`).val() === ""){
                    $(`#name_driver_${container}`).prop('required',false);
                    $(`#last_name_${container}`).prop('required',false);
                    $(`#birth_date_${container}`).prop('required',false);
                    $(`#driver_license_${container}`).prop('required',false);
                    $(`#country_driver_${container}`).prop('required',false);
                }else{
                    $(`#name_driver_${container}`).prop('required',true);
                    $(`#last_name_${container}`).prop('required',true);
                    $(`#birth_date_${container}`).prop('required',true);
                    $(`#driver_license_${container}`).prop('required',true);
                    $(`#country_driver_${container}`).prop('required',true);
                }
                // Chequeo de la cantidad de choferes a cobrar y cantidad libre.
                checkDriverAmount(e);
            }   ,
            error: function (jqXHR, textStatus, errorThrown) {
                if (jqXHR.getResponseHeader('REQUIRES_AUTH') === '1') {
                    alert('Su session expiró, por favor acceda nuevamente');
                    window.location = jqXHR.getResponseHeader('ROOT_URL');
                }else{
                    let message = "";
                    if (jqXHR.status === 0) {
                        message = 'Sin conexión: Verifique su conexión de red.';
                    } else if(jqXHR.status == 400){
                        message = ' Existen problemas en el request';
                    } else if(jqXHR.status == 401){
                        message = 'Autentiquece nuevamente.';
                    }else if (jqXHR.status == 403) {
                        message = "Autentiquece nuevamente.";
                    } else if (jqXHR.status == 404) {
                        message = 'Página no encontrada [404]';
                    } else if (jqXHR.status == 500) {
                        message = 'Ha ocurrido un error [500] que fue notificado al equipo de soporte, intene nuevamente en unos minutos.';
                    } else if (jqXHR.status == 503){
                        message = "Service unavailable.";
                    } else if (textStatus === 'parsererror') {
                        message = 'Ha ocurrido un error de interno [JSON] que fue notificado al equipo de soporte, intene nuevamente en unos minutos';
                    } else if (textStatus === 'timeout') {
                        message = 'Time out, tiempo de espera superado, inicie nuevmente, verifique su conexión de red.';
                    } else if (textStatus === 'abort') {
                        message = 'Request abortado.';
                    } else {
                        message = 'Error desconocido: ' + jqXHR.responseText;
                    }
                    alert(message);
                }
            },
        });
        } else {
            checkDriverAmount(e);
        }
    }

    updateDriverImport(e){
        // Reset valor del importe por chofer
        let element_drivers_import = $('#drivers_import');
        element_drivers_import.val(0.0).trigger('change');
        // Calcular el valor del importe por chofer
        let days = parseInt($('#drivers_days').val());
        let tariff_type = this.data.get('driver_tariff_type');
        let drivers_import = 0.0;
        if (tariff_type === 'by_range') {
            drivers_import = (parseFloat($('#tariff_drivers_day').val()) * parseFloat($('#additional_driver_collect').val())).toFixed(2)
        } else {
            drivers_import = (days * parseFloat($('#tariff_drivers_day').val()) * parseFloat($('#additional_driver_collect').val())).toFixed(2)
        }
        element_drivers_import.val(drivers_import).trigger('change');
    }

    updatePaymentForm(e){
        let drivers = parseFloat($('#drivers_import').val());
        let other_payments = parseFloat($('#other_payments').val());
        let total = myToFixed(drivers + other_payments, 2);
        let payment_total_voucher = this.data.get('payment_total_voucher');
        // Verificar configuracion del tipo de renta, si tiene configurado que el cobro total del contrato para voucher
        if (payment_total_voucher === 'true') {
            $('#voucher').val(total);
            $('#payment_method_total').val(0.00);
            $('#total').val(0.00);
        } else {  // nota: solo si no es voucher se aplica uso de Bolsa (credit pool)
            if(this.credit_pool > 0) // aplicar descuento de bolsa al total si hay disponible ...
                total = applyCreditPoolDiscountToAmount(Number(total), this.credit_pool, this.$credit_pool_msg_details);
            $('#payment_method_total').val(total)
            $('#total').val(total)
            checkAdvancePaymentAtMoment();
        }
    }

    updateTotal(e) {
        let cash = $('#cash').val() !== '' ? parseFloat($('#cash').val()) : 0.0;
        let card = $('#card').val() !== '' ? parseFloat($('#card').val()) : 0.0;
        let moment_card = $('#moment_card').val() !== '' ? parseFloat($('#moment_card').val()) : 0.0;
        let voucher = $('#voucher').val() !== '' ? parseFloat($('#voucher').val()) : 0.0;
        let cheque = $('#cheque').val() !== '' ? parseFloat($('#cheque').val()) : 0.0;
        let other = $('#other').val() !== '' ? parseFloat($('#other').val()) : 0.0;
        let total = cash + card + moment_card + voucher + cheque + other
        let to_distribute = parseFloat($('#total').val());
        // Cheaquando configuracion por tipo de renta total del cobros por voucher
        let payment_total_voucher = this.data.get('payment_total_voucher');
        if (payment_total_voucher === 'true') {
            // $('#payment_method_total').val(0.00);
            $('#btn-addDrivers').removeClass('disabled');
        } else {
            $('#payment_method_total').val(to_distribute - total);
            if ((to_distribute - total) < 0) {
                alert('No puede repartir más de $' + to_distribute + ', verifique los datos')
            }
            if ((to_distribute - total) === 0) {
                $('#btn-addDrivers').removeClass('disabled');
            } else {
                $('#btn-addDrivers').addClass('disabled');
            }
        }
    }

    ajaxPaymentFormsByRentType(operation){
        $.blockUI({ message:false });
        jQuery.ajax({
            async:true,
            evalScripts: true,
            type: 'GET',
            contentType: "application/x-www-form-urlencoded",
            url: `${location.origin}/hire_point/payments_form/${operation}.json`,
            data: {rent_operation: operation},
            success: function (pays){
                $.unblockUI();
                if(pays.payment_forms.length > 0){
                    let moment_card = $('#moment_card');
                    let card = $('#card');
                    let cash = $('#cash');
                    let voucher = $('#voucher');
                    let cheque = $('#cheque');
                    let other = $('#other');

                    for(var i = 0; i < pays.payment_forms.length ;i++){

                        if(pays.payment_forms[i].code === 'EFE')
                            cash.removeAttr("disabled");
                        if(pays.payment_forms[i].code === 'TM')
                            moment_card.removeAttr("disabled");
                        if(pays.payment_forms[i].code === 'VOU')
                            voucher.removeAttr("disabled");
                        if(pays.payment_forms[i].code === 'TAN')
                            card.removeAttr("disabled");
                        if(pays.payment_forms[i].code === 'CHT')
                            cheque.removeAttr("disabled");
                        if(pays.payment_forms[i].code === 'OTR')
                            other.removeAttr("disabled");
                        // console.log("formas de pago definidas: " + pays.payment_forms[i].code);

                    }

                }else{
                    console.log("ESTA OPERACION DE RENTA NO TIENE FORMAS DE PAGO DEFINIDAS");
                }

            },
            error: function () {
                $.unblockUI();
                console.log("Existen errores procesando esta peticion.")
            },
        });
    };

    licencePermanent(e){
        let container = e.target.dataset.container;
        let due_date = $(`#due_date_${container}`);
        if (e.target.checked) {
            due_date.prop('disabled', true);
            due_date.prop('required', false)
        } else {
            due_date.prop('disabled', false);
            due_date.prop('required', true)
        }
    }

    /**
     * Chequear si la fecha del chofer se encuentra dentro de periodo que dura la renta.
    **/
    checkDueDate(e){
        let container = e.target.dataset.container;
        // Fecha de vencimiento de la licencia
        let due_date_container = $(`#due_date_${container}`);
        let a_due_date = due_date_container.val().split('/');
        let due_date = new Date(a_due_date[1] + '/' + a_due_date[0] + '/' + a_due_date[2]);
        // Fecha limite de la renta, si no se pasa como parametro se busca en el campo to_date del formulario
        let rent_to_date = e.target.dataset.rent_to_date;
        if (rent_to_date === undefined) rent_to_date = $('#to_date').val();
        let a_to_date = rent_to_date.split('/');
        let to_date = new Date(a_to_date[1] + '/' + a_to_date[0] + '/' + a_to_date[2]);
        if (due_date <= to_date){
            toastr_message('error', 'La licencia está vencida o se encuentra dentro del período que dura la renta. Por favor, verifique la fecha de vencimiento.', 'Licencia inválida');
            due_date_container.val('');
        }
    }
}