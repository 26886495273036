import {Controller} from "stimulus";
import $ from "jquery";
import {ajaxErrorMessages} from "./globalFunctions_controller";
require('block-ui/jquery.blockUI');

export default class extends Controller {
    connect() {
        $('.c2ec_select').select2({
            theme: "bootstrap",
            dropdownAutoWidth: true,
            dropdownCssClass: 'input-border-color mt-1 border',
            selectionCssClass: 'shadow-none form-control input-border-color rounded',
            language: 'es'
        });

        $('#om_contract_email').select2({
            theme: "bootstrap",
            dropdownAutoWidth: true,
            dropdownCssClass: 'input-border-color mt-1 border',
            language: 'es',
            tags: true,
            createTag: function (params) {
                const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                if (emailRegex.test(params.term)) return { id: params.term, text: params.term };
                else return null;
            }
        });
    }

    findEmails(e) {
        let url = this.data.get('url');
        $.blockUI({message: false});
        jQuery.ajax({
            async: true,
            evalScripts: true,
            type: "POST",
            contentType: "application/x-www-form-urlencoded",
            url: url,
            data: {client_id: e.target.value},
            success: function (res) {
                $.unblockUI();
                let s2results = $("#om_contract_email");
                s2results.empty();
                s2results.append('<option selected="selected" value=""></option>');
                if (res !== undefined) {
                    $.each(res.emails, function (index, email) {
                        s2results.append(
                            $("<option>")
                                .text(email)
                                .attr("value", email)
                        );
                    });
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                $.unblockUI();
                ajaxErrorMessages(jqXHR, textStatus, errorThrown);
            },
        });
    }
}