import {Controller} from "stimulus";
import $ from "jquery";
import {ajaxErrorMessages, toastr_message} from "./globalFunctions_controller";

require('block-ui/jquery.blockUI');

function myToFixed(num, precision) {
    return (+(Math.round(+(num + 'e' + precision)) + 'e' + -precision)).toFixed(precision);
}

export default class extends Controller {
    connect() {
    }
    onChangeModality(e){
        let account_status = $('#account_status');
        if (e.target.value === 'FMEX'){
            account_status.prop('disabled', false);
            account_status.prop('required',true);
        }else{
            account_status.prop('disabled', true);
            account_status.prop('required',false);
            account_status.val(null).trigger('change');
        }
    }
    calculateNetAmount(e){
        let sucommercial_id = $('#subcommercial_id').val();
        let commission = e.target.value;
        if(!isNaN(commission) && commission.length > 0 ){
            let parentNodeId = e.target.parentNode.id;
            let amount_sales = $('#'+parentNodeId).prev().text();
            let amount_net = myToFixed(amount_sales-commission, 2)
            let next = $('#'+parentNodeId).next();
            next.html(amount_net);
        }else {
            e.target.value = myToFixed(0.0, 2)
        }
        this.totalCommission(sucommercial_id);
        this.totalNetAmount(sucommercial_id);
        this.updateStatus(sucommercial_id);
    }
    totalCommission(id) {
        let totalCommission = 0.00;
        $.each($('.commission_item_'+id), function (key, item) {
            totalCommission += parseFloat(item.value);
        });
        $("#commission_total_"+id).html(myToFixed(totalCommission, 2));
    }

    totalNetAmount(id) {
        let totalNetAmount = 0.00;
        $.each($('.net_item_'+id), function (key, item) {
            totalNetAmount += parseFloat(item.innerText);
        });
        $("#net_amount_total_"+id).html(myToFixed(totalNetAmount, 2));
    }

    updateStatus(id) {
        let subCommercialCommission = parseFloat($("#subcommercial_commission_" + id)[0].textContent);
        let subCommercialNet = parseFloat($("#subcommercial_net_" + id)[0].textContent);
        let commissionTotal = parseFloat($("#commission_total_" + id)[0].textContent);
        let netAmountTotal = parseFloat($("#net_amount_total_" + id)[0].textContent);

        if (subCommercialCommission === commissionTotal && subCommercialNet === netAmountTotal) {
            $("#subcommercial_total_" + id).addClass('bg-green-lt');
        } else {
            $("#subcommercial_total_" + id).addClass('bg-red-lt');
        }
    }

}